<template>
	<div style="display: flex; flex-wrap: wrap" id="date-tabs">
		<b-tabs class="flex-nowrap" pills nav-class="nav-pill-primary" v-on:activate-tab="tabJump" v-model="tabIndex">
			<!-- <b-tab :title="'Период: ' + this.$store.state.appstats.diff_text" /> -->
			<b-tab title="Год" />
			<b-tab title="Месяц" />
			<b-tab title="Неделя" />
			<b-tab title="День" />
			<b-tab title-item-class="d-none"></b-tab>
		</b-tabs>
		<div id="dates">
			<b-button @click="() => tabJump('prev')" variant="flat-primary" class="btn-icon" style="padding: 0.3em; margin-right: 0.7em; margin-bottom: 1em">
				<feather-icon icon="ChevronLeftIcon" size="30" />
			</b-button>
			<div class="date-wrap">
				<b-form-datepicker :max="$store.state.appstats.end_date" id="start_date" placeholder="Choose a date" locale="ru" v-model="start_date_form" />
				<b-form-datepicker :min="$store.state.appstats.start_date" id="end_date" placeholder="Choose a date" locale="ru" v-model="end_date_form" />
			</div>
			<b-button @click="() => tabJump('next')" variant="flat-primary" class="btn-icon" style="padding: 0.3em; margin-left: 0.7em; margin-bottom: 1em">
				<feather-icon icon="ChevronRightIcon" size="30" />
			</b-button>
		</div>
	</div>
</template>

<script>
import { BRow, BCol, BTabs, BTab, BPopover, BFormDatepicker, BButton } from 'bootstrap-vue'
import { add, sub } from 'date-fns'

export default {
	props: ['chartData', 'chartMeta'],
	components: {
		BRow,
		BCol,
		BTabs,
		BTab,
		BPopover,
		BFormDatepicker,
		BButton
	},
	data() {
		return {
			tabIndex: (() => {
				if(this.$store.state.appstats.detached) return 4;
				const a = ['year','month','week','day'];
				let index = a.indexOf(this.$store.state.appstats.interval);
				return index === undefined ? 2 : index 
			})(),
		}
	},
	computed: {
		start_date_form: {
			get() {
				return this.$store.state.appstats.start_date
			},
			set(value) {
				this.$store.commit('appstats/set_start', new Date(value).toISOString())
				this.$store.commit('appstats/set_detached', true)
				this.$emit('date-changed')
				this.tabIndex = 4;
			},
		},
		end_date_form: {
			get() {
				return this.$store.state.appstats.end_date
			},
			set(value) {
				console.log(value)
				let d = new Date(value);
				this.$store.commit('appstats/set_end', new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999)).toISOString())
				this.$store.commit('appstats/set_detached', true)
				this.$emit('date-changed')
				this.tabIndex = 4;
			},
		},
	},
	methods: {
		tabJump(newIndex, oldIndex, e) {
			if(newIndex == 4) {
				return;
			}
			let a = new Date(this.$store.state.appstats.start_date)
			let b = new Date(this.$store.state.appstats.end_date)
			let operation = newIndex === 'prev' ? sub : add
			if (newIndex === 'prev' || newIndex === 'next') {
				if(e) e.preventDefault()
				if(this.$store.state.appstats.detached) {
					let diff = b - a + 1;
					diff *= operation==sub?-1:1;
					this.$store.commit(
						'appstats/set_start',
						new Date(+a + diff).toISOString(),
					)
					this.$store.commit(
						'appstats/set_end',
						new Date(+b + diff).toISOString(),
					)
					this.$emit('date-changed')
					return;
				}
			} else {
				let region = ['year', 'month', 'week', 'day'][newIndex]
				let date = new Date();
				let base = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999))
				switch (region) {
					case 'year':
						this.$store.commit(
							'appstats/set_start',
							new Date(Date.UTC(new Date().getFullYear(), 0, 1)).toISOString(),
						)
						this.$store.commit(
							'appstats/set_end',
							new Date(Date.UTC(new Date().getFullYear() + 1, 0, 0, 23, 59, 59, 999)).toISOString(),
						)
						break
					case 'month':
						this.$store.commit(
							'appstats/set_start',
							new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString(),
						)
						this.$store.commit(
							'appstats/set_end',
							new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59,999)).toISOString(),
						)
						break
					case 'week':
						this.$store.commit(
							'appstats/set_start',
							new Date(base - 1000 * 60 * 60 * 24 * 7 + 1).toISOString(),
						)
						this.$store.commit('appstats/set_end', base.toISOString())
						break
					case 'day':
						this.$store.commit(
							'appstats/set_start',
							new Date(base - 1000 * 60 * 60 * 24 + 1).toISOString(),
						)
						this.$store.commit('appstats/set_end', base.toISOString())
						break
				}
				this.$store.commit('appstats/set_interval', region)
				this.$emit('date-changed')
				return
			}
			let interval = this.$store.state.appstats.interval
			let shift = {}
			shift[interval + 's'] = 1
			if (shift && operation) {
				this.$store.commit('appstats/set_start', operation(a, shift).toISOString())
				this.$store.commit('appstats/set_end', operation(b, shift).toISOString())
			}
			this.$emit('date-changed')
			// this.$store.state.appstats.interval = 'interval';
		},
	},
	created() {

	},
}
</script>


<style>
#date-tabs {
	/* margin-right: 2em; */
}

#dates {
	display: flex;
	align-items: flex-start;
	flex-grow: 1;
	flex-wrap: nowrap;
	margin-left: 2em;
	align-items: center;
}
#dates .nav-pill-primary {
	flex-wrap: nowrap !important;
}

#dates > .date-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-left: 1em;
}

#dates > .date-wrap > div:first-child {
	margin-left: -1em;
	margin-right: 2em;
}
#dates > .date-wrap > div:last-child {
	margin-left: -1em;
}
#dates > .date-wrap > div {
	width: 23em;
	margin-bottom: 1em;
}

</style>