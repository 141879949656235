<template>
	<b-card no-body>
		<b-card-header>
			<div>
				<b-card-title class="mb-1"> {{ chartMeta[type].title.text }} </b-card-title>
			</div>
			<b-form-group>
				<b-form-radio-group
					v-model="selected"
					:options="oneRange"
					name="buttons-1"
					buttons
					button-variant="flat-primary"
				/>
			</b-form-group>

			<!-- <b-button-group>
				<template v-for="range in $store.state.appstats.ranges" v-bind:range="range">
					<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" :key="range">
						{{ rangeMap[range] }}
					</b-button>
				</template>
			</b-button-group> -->
		</b-card-header>

		<b-card-body>
			<vue-apex-charts type="line" :stacked=false height="400" :options="chartMeta[type]" :series="chartAll" />
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardHeader,
	BCardTitle,
	BCardSubTitle,
	BBadge,
	BButton,
	BButtonGroup,
	BFormGroup,
	BFormRadioGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
	props: ['chartData', 'chartMeta', 'type'],
	components: {
		VueApexCharts,
		BCardHeader,
		BCard,
		BBadge,
		BCardBody,
		BCardTitle,
		BCardSubTitle,
		BButton,
		BButtonGroup,
		BFormGroup,
		BFormRadioGroup,
	},
	directives: {
		Ripple,
	},
	computed: {
		chartAll: {
			get() {
				if(!this.chartData[this.selected]) return []
				return this.chartData[this.selected][this.type];
			}
		}
	},
	watch: {
		'$store.state.appstats.ranges': function() {
			let last = this.$store.state.appstats.ranges.length - 1
			this.oneRange = [this.$store.state.appstats.ranges[last]]
			this.selected = this.oneRange[0].value
			// if(!this.$store.state.appstats.ranges.map(i => i.value).includes(this.selected)) {
			// }
		}
	},
	data() {
		return {
			rangeMap: {
				day: 'Day',
				hour: 'Hour',
				month: 'Month',
				year: 'Year',
			},
			selected: 'months',
			oneRange: []
		}
	},
	created() {
		let last = this.$store.state.appstats.ranges.length - 1
		this.oneRange = [this.$store.state.appstats.ranges[last]]
		this.selected = this.oneRange[0].value
	}
}
</script>
