<template>
	<b-card no-body>
		<b-card-header>
			<div>
				<b-card-title class="mb-1"> {{ data.title }} </b-card-title>
			</div>
			<b-form-group>
				<b-form-radio-group
					v-model="selected"
					:options="$store.state.appstats.ranges"
					name="buttons-1"
					buttons
					button-variant="outline-primary"
				/>
			</b-form-group>
		</b-card-header>

		<b-card-body>
			<vue-apex-charts type="line" height="300" :options="chartOptions" :series="series" />
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardHeader,
	BCardTitle,
	BCardSubTitle,
	BBadge,
	BButton,
	BButtonGroup,
	BFormGroup,
	BFormRadioGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
	props: ['data'],
	components: {
		VueApexCharts,
		BCardHeader,
		BCard,
		BBadge,
		BCardBody,
		BCardTitle,
		BCardSubTitle,
		BButton,
		BButtonGroup,
		BFormGroup,
		BFormRadioGroup,
	},
	directives: {
		Ripple,
	},
	computed: {
		series: {
			get() {
				return this.data.intervals[this.selected];
			},
		},
	},
	watch: {
		'$store.state.appstats.ranges': function () {
			// if(!this.$store.state.appstats.ranges.map(i => i.value).includes(this.selected)) {
			this.selected = this.$store.state.appstats.ranges[0].value
			// }
		},
	},
	data() {
		return {
			rangeMap: {
				hour: 'Час',
				day: 'День',
				month: 'Месяц',
				year: 'Год',
			},
			selected: this.$store.state.appstats.ranges[0].value,
			chartOptions: {
				xaxis: { type: 'datetime' },
				chart: {
					toolbar: {
						show: true,
						tools: {
							download: true,
							selection: false,
							zoom: false,
							zoomin: false,
							zoomout: false,
							pan: false,
							reset: false,
						},
					},
				},
				tooltip: {
					x: {
						format: 'dd.MM.yyyy HH:mm',
					},
				},
				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'round',
					width: 3,
				},
			},
		}
	},
	created() {
		Object.assign(this.chartOptions, this.data.options)
	},
}
</script>
