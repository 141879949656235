<template>
	<b-card no-body>
		<b-card-header>
			<div>
				<b-card-title class="mb-1"> {{ data.title }} </b-card-title>
			</div>
			<b-form-group>
				<b-form-radio-group
					v-model="selected"
					:options="oneRange"
					name="buttons-1"
					buttons
					button-variant="outline-primary"
				/>
			</b-form-group>
		</b-card-header>

		<b-card-body>
			<vue-apex-charts type="line" height="400" :options="chartOptions" :series="chartAll" />
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard,
	BCardBody,
	BCardHeader,
	BCardTitle,
	BCardSubTitle,
	BBadge,
	BButton,
	BButtonGroup,
	BFormGroup,
	BFormRadioGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
	props: ['data'],
	components: {
		VueApexCharts,
		BCardHeader,
		BCard,
		BBadge,
		BCardBody,
		BCardTitle,
		BCardSubTitle,
		BButton,
		BButtonGroup,
		BFormGroup,
		BFormRadioGroup,
	},
	directives: {
		Ripple,
	},
	computed: {
		chartAll: {
			get() {
				return this.data.intervals[this.selected];
			}
		}
	},
	watch: {
		'$store.state.appstats.ranges': function() {
			let last = this.$store.state.appstats.ranges.length - 1
			this.oneRange = [this.$store.state.appstats.ranges[last]]
			this.selected = this.oneRange[0].value
			// if(!this.$store.state.appstats.ranges.map(i => i.value).includes(this.selected)) {
			// }
		}
	},
	data() {
		return {
			rangeMap: {
				day: 'День',
				hour: 'Час',
				month: 'Месяц',
				year: 'Год',
			},
			selected: 'month',
			oneRange: [],
			chartOptions: {
				xaxis: { type: 'datetime' },
				chart: {
					type: 'bar',
					stacked: true,
					toolbar: {
						show: true,
						tools: {
							download: true,
							selection: false,
							zoom: false,
							zoomin: false,
							zoomout: false,
							pan: false,
							reset: false,
						},
					},
				},
				tooltip: {
					x: {
						format: 'dd.MM.yyyy HH:mm',
					},
				},
				legend: {
					onItemClick: {
						toggleDataSeries: false
					}
				},
			}
		}
	},
	created() {
		Object.assign(this.chartOptions, this.data.options);
		let last = this.$store.state.appstats.ranges.length - 1
		this.oneRange = [this.$store.state.appstats.ranges[last]]
		this.selected = this.oneRange[0].value
	}
}
</script>
