<template>
	<b-card no-body>
		<b-card-header>
			<b-card-title>{{ chartMeta.title }}</b-card-title>
		</b-card-header>

		<b-card-body>
			<!-- apex chart -->
			<vue-apex-charts height="300px" class="mt-2 mb-1" :options="chartOptions" :series="chartData" />

			<!-- list group -->
			<!-- <div class="pt-25">
				<div
					v-for="(data, index) in chartData.listData"
					:key="index"
					class="d-flex justify-content-between"
					:class="index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'"
				>
					<div class="series-info">
						<feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor" />
						<span class="font-weight-bolder">{{ data.text }}</span>
					</div>
					<span>{{ data.result }}</span>
				</div>
			</div> -->
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
	props: ['chartData', 'chartMeta', 'type'],
	components: {
		BCard,
		BCardHeader,
		BCardTitle,
		BDropdown,
		BDropdownItem,
		BCardBody,
		VueApexCharts,
	},
	data() {
		return {
			chartOptions: {
				chart: {
					type: 'pie',
					toolbar: {
						show: false,
					},
					height: '400px',
					events: {
							click: (a,b,c) => {
								console.log(a,b,c)
							}
						}
				},
				dataLabels: {
					enabled: true,
				},
				legend: { show: true, position: 'bottom' },
				stroke: {
					width: 4,
				},
				colors:  this.chartMeta.colors
			},
		}
	},
	created() {
		if(this.chartMeta?.options) {
			this.chartOptions = this.chartMeta.options
			this.chartOptions.series = this.chartData;
		}else
			this.chartOptions.labels = this.chartMeta.legend
		// this.$http.get('/card/card-analytics/customers').then(res => {
		//   this.chartData = res.data
		// })
	},
}
</script>
