<template>
	<b-card no-body>
		<b-card-header>
			<b-card-title>{{ data.title }}</b-card-title>
		</b-card-header>

		<b-card-body>
			<vue-apex-charts height="300px" class="mt-2 mb-1" :options="chartOptions" :series="data.series" />
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
	props: ['data'],
	components: {
		BCard,
		BCardHeader,
		BCardTitle,
		BDropdown,
		BDropdownItem,
		BCardBody,
		VueApexCharts,
	},
	data() {
		return {
			chartOptions: {
				chart: {
					type: 'bar',
					toolbar: {
						show: false,
					},
					height: '400px'
				},
				dataLabels: {
					enabled: true,
				},
				legend: { show: true, position: 'bottom' },
				stroke: {
					width: 4,
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						horizontal: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					type: 'category',
				},
			},
		}
	},
	created() {
		Object.assign(this.chartOptions, this.data.options)
	},
}
</script>
